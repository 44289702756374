import { usePathname } from 'next/navigation'
import { HomepageContent } from '@/components/pages/homepage/HomepageContent'
import { Header } from '@/components/elements/header/Header'
import { Footer } from '@/components/elements/new-footer/Footer'
import { useSetTrackingSection } from '@/lib/hooks/useSetTrackingSection'
import { SEOMetaTags } from '@/components/shared/SEOMetaTags'

export const Homepage = ({ page }) => {
  useSetTrackingSection('homepage')

  return (
    <>
      <SEOMetaTags metaData={page.metadata} path="/" />

      <Header ctaText="Join ZOE" />

      <HomepageContent content={page.content} />

      <Footer showPreFooter />
    </>
  )
}
