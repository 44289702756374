import React from 'react'
import styled from 'styled-components'
import { GridBlock, GridBlockRecord } from './GridBlock'
import { BREAKPOINTS } from '@/components/ds/breakpoints'

const videoFragment = `
  streamingUrl
  mp4High: mp4Url(res: high)
  mp4Med: mp4Url(res: medium)
  mp4Low: mp4Url(res: low)
  duration
  framerate
  thumbJpg: thumbnailUrl(format: jpg)
  thumbPng: thumbnailUrl(format: png)
  thumbGif: thumbnailUrl(format: gif)
`

const fragment = `
  fragment VideoCoverBlockFragment on VideoCoverRecord {
    __typename
    id
    maxWidth
    video {
      video {
        ${videoFragment}
      }
    }
    content {
      ...GridBlockFragment
    }
  }

`

type VideoType = {
  video: {
    streamingUrl?: string
    mp4High: string
    mp4Med: string
    mp4Low: string
    duration: number
    framerate: number
    thumbJpg: string
    thumbPng: string
    thumbGif: string
  }
}

type VideoCoverRecord = {
  id?: string
  video?: VideoType
  maxWidth?: number
  content?: GridBlockRecord
}

const StyledVideoContainer = styled.div<{ $maxWidth?: number }>`
  position: relative;
  display: flex;
  height: 712px;
  align-items: center;
  justify-content: center;
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '100%')};
  margin: 0 auto;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    height: 518px;
    align-items: flex-end;
  }
  ${({ $maxWidth }) =>
    $maxWidth &&
    ` @media screen and (min-width: ${$maxWidth}px) {
      border-radius: var(--radius-16);
      overflow: hidden;
    }`};
`
const StyledVideo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(24deg, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 85%);
    mix-blend-mode: overlay;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
`

const StyledVideoContent = styled.div`
  z-index: 3;
`

export const VideoCoverBlock = ({ record }: { record: VideoCoverRecord }) => {
  return (
    <StyledVideoContainer $maxWidth={record?.maxWidth}>
      <StyledVideo>
        <video id="loop" preload="auto" playsInline autoPlay loop muted poster={record?.video?.video.thumbPng}>
          <source src={record?.video?.video.mp4High} />
        </video>
      </StyledVideo>
      {record.content && (
        <StyledVideoContent>
          <GridBlock record={record?.content} />
        </StyledVideoContent>
      )}
    </StyledVideoContainer>
  )
}

VideoCoverBlock.fragment = fragment
VideoCoverBlock.recordName = 'VideoCoverRecord'
